// TODO: naming convention

export interface Tracking {
  created_by: string;
  updated_by: string;
  created_on: string;
  updated_on: string;
}

export class Tracking {
  constructor() {
    this.created_by = 'a';
    this.updated_by = null;
    this.created_on = null;
    this.updated_on = null;
  }
}
