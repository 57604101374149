import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { ConfigurationsService } from '../../service/configurations.service';
import * as configActions from '../actions/configuration.actions';
import { Configuration } from '../models/configurations.model';

@Injectable()
export class ConfigurationsEffects {

  constructor(
    private readonly actions$: Actions,
    private store: Store,
    private configService: ConfigurationsService
  ) { }

  getAllConfigurations$ = createEffect(() => this.actions$.pipe(
    ofType(configActions.getAllConfigs),
    switchMap(() => this.configService.getAllConfigurations().pipe(
      map((configList: Configuration[]) => configActions.getAllConfigsSuccess({ data: configList })),
      catchError((errorResponse) => of(configActions.getAllConfigsFail(errorResponse)))
    )),
  ));
}
