import { createReducer, on } from '@ngrx/store';
import * as configActions from '../actions/configuration.actions';
import { ConfigurationsState } from '../models/core.model';

export const initialState: ConfigurationsState = {
    loading: false,
    list: [],
    error: ''
};

export const configurationsFeatureKey = 'config';

export const configurationsReducer = createReducer(
    initialState,
    on(configActions.getAllConfigs, (state) => (
        {
            ...state,
            loading: true,
            error: ''
        }
    )),
    on(configActions.getAllConfigsFail, (state, { error }) => (
        {
            ...state,
            loading: false,
            error: error
        }
    )),
    on(configActions.getAllConfigsSuccess, (state, { data }) => (
        {
            ...state,
            loading: false,
            list: data
        }
    ))
);
