import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class ExcelService {
  createExcel(
    jsonData: Record<string, unknown>[],
    sheetName: string,
  ): BlobPart {
    const sheet = XLSX.utils.json_to_sheet(jsonData);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, sheetName);
    return XLSX.write(book, { bookType: 'xlsx', type: 'buffer' });
  }
}
