import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { configurationsMock } from "../store/mocks"
import { Configuration } from "../store/models/configurations.model"


@Injectable()
export class ConfigurationsService {
  getAllConfigurations(): Observable<Configuration[]> {
    return of(configurationsMock)
  }
}

