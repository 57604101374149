import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgMapsCoreModule } from '@ng-maps/core';
import { NgMapsGoogleModule } from '@ng-maps/google';
import { TranslateModule } from '@ngx-translate/core';
import { InlineEditComponent } from './inline-edit.component';
import { DirectiveModule } from '@directive';

@NgModule({
  declarations: [InlineEditComponent],
  exports: [InlineEditComponent],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    NgMapsGoogleModule,
    NgMapsCoreModule,
    DirectiveModule,
  ],
})
export class InlineEditModule {}
