import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DialogComponent } from '@component';
import { DialogConfig } from '@model';

@Injectable()
export class DialogService {
  private readonly dialogOptions = new MatDialogConfig();

  constructor(private dialog: MatDialog) {
    this.setDialogOptions();
  }

  openDialog(
    data: DialogConfig,
    materialOptions?: MatDialogConfig,
  ): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      ...this.dialogOptions,
      ...materialOptions,
      data,
    });
  }

  private setDialogOptions(): void {
    this.dialogOptions.autoFocus = false;
    this.dialogOptions.closeOnNavigation = true;
    this.dialogOptions.hasBackdrop = true;
    this.dialogOptions.disableClose = true;
  }
}
