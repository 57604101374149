import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { UploaderComponent } from './uploader.component';
import { UploaderService } from './uploader.service';

@NgModule({
  declarations: [UploaderComponent],
  exports: [UploaderComponent],
  providers: [UploaderService],
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    CommonModule,
    MatIconModule,
    MatRippleModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
})
export class UploaderModule {}
