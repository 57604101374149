import { Configuration } from "./models/configurations.model";

export const configurationsMock: Configuration[] = [
  {
    id: '1',
    name: 'Test 1'
  },
  {
    id: '2',
    name: 'test 2'
  }
]