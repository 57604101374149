import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'rtu-dashboard-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  updates: string[];
  constructor(
    private route: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    ) {
      this.matIconRegistry.addSvgIcon('noData', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg-icons/no-data.svg'));
    }

  ngOnInit(): void {
    this.updates = this.route.snapshot.data['updates'];
  }

  goTo(url: string): void {
    window.open(url, '_blank');
  }
}
