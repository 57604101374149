import {
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UploaderService<T> {
  object: T | T[];

  constructor(private http: HttpClient) {}

  upload(
    files: Set<File>,
    url: string,
    method: 'post' | 'put',
    formDataParameters: Map<string, string>,
  ): {
    [key: string]: { progress: Observable<number> };
  } {
    // this will be the resulting map
    const status: { [key: string]: { progress: Observable<number> } } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('csvfile', file, file.name);
      let listParams = new HttpParams();
      listParams = listParams.set('filename', file.name);
      formDataParameters.forEach((value: string, key: string) => {
        listParams = listParams.append(key, value);
      });

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest(
        method.toUpperCase(),
        `${url}?${listParams.toString()}`,
        formData,
        {
          reportProgress: true,
        },
      );

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe({
        next: event => {
          if (event.type === HttpEventType.UploadProgress) {
            // calculate the progress percentage
            const percentDone = Math.round((100 * event.loaded) / event.total);

            // pass the percentage into the progress-stream
            progress.next(percentDone);
          } else if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            if (files.size === 1) {
              this.object = this.object as T;
              this.object = event.body['data'];
            } else {
              this.object = this.object as T[];
              this.object.push(event['data']);
            }
            progress.complete();
          }
        },
        error: (err: unknown) => progress.error(err),
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable(),
      };
    });

    // return the map of progress.observables
    return status;
  }
}
