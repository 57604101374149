export interface User {
  username: string;
  userId: string;
  userType: string;
  userClass: string;
  primaryOrg: string;
  firstName: string;
  lastSystemName: string;
  fullname: string;
  active: boolean;
  brand: string;
  locale: string;
  email: string;
  phone: string;
  countryCode: string;
  fullyTranslatedName: string;
}

export class User {
  constructor() {
    this.username = null;
    this.userId = null;
    this.userType = null;
    this.userClass = null;
    this.primaryOrg = null;
    this.firstName = null;
    this.lastSystemName = null;
    this.fullname = null;
    this.active = false;
    this.brand = null;
    this.locale = null;
    this.email = null;
    this.phone = null;
    this.countryCode = null;
    this.fullyTranslatedName = '';
  }
}
