export interface ColumnOptions {
  id: string;
  translatedKey: string;
  showUnitInBody: boolean;
}

export class ColumnOptions {
  constructor(id: string, translateKey: string, showUnitInBody: boolean) {
    this.id = id;
    this.translatedKey = translateKey;
    this.showUnitInBody = showUnitInBody;
  }
}
