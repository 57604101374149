import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { CoreStore } from "./store/models/core.model";
import { configurationsReducer } from "./store/reducers/configurations.reducer";



export const reducers: ActionReducerMap<CoreStore> = {
  config: configurationsReducer,
};

export const getCoreStore = createFeatureSelector<CoreStore>('core');