import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MapsAPILoader } from '@ng-maps/core';
import { Observable } from 'rxjs';
import { Location } from '@model';

@Component({
  selector: 'rtu-dashboard-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
})
export class InlineEditComponent<T> implements OnChanges {
  @Input() htmlTag: 'h1' | 'span' | 'div';
  @Input() inputType: 'input' | 'checkbox' | 'textarea' | 'select' | 'map';
  @Input() formControl: UntypedFormControl;

  @Input() label: string;
  @Input() fieldToUpdate: string;

  @Input() selectArray?: T[];
  @Input() selectDisplayField?: keyof T;
  @Input() selectValueField?: keyof T;
  @Input() selectIdField?: keyof T;

  @Input() latitudeField?: string;
  @Input() longitudeField?: string;
  @Input() nodeToAttachMap?: HTMLElement;

  latitude: number;
  longitude: number;
  showMap = false;

  private geoCoder;

  @ViewChild('mapSearch') mapSearch: ElementRef;
  @ViewChild('select') matSelect: MatSelect;

  @Input() callback: (
    formControl: UntypedFormControl,
    fieldToUpdate: string,
  ) => Observable<unknown>;

  @Output() formControlChange = new EventEmitter<UntypedFormControl>();

  isEditing = false;
  innerHtml: string;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.showMap = false;
    if (this.inputType === 'map' && this.formControl) {
      this.latitude = +this.formControl.value[this.latitudeField];
      this.longitude = +this.formControl.value[this.longitudeField];
    }
    this.refreshInnerHtml(this.label);
  }

  editMode(): void {
    this.showMap = false;
    this.isEditing = true;
    if (this.inputType === 'map') {
      setTimeout(() => this.initAutocomplete());
    }
    if (this.inputType === 'select') {
      setTimeout(() => this.matSelect.open());
    }
  }

  editingEnd(label: string): void {
    if (!this.formControl.valid) return;
    if (!this.formControl.dirty) {
      this.escWithoutSaving();
      return;
    }
    this.callback(this.formControl, this.fieldToUpdate).subscribe(() => {
      this.formControlChange.emit(this.formControl);
      this.refreshInnerHtml(label);
      this.isEditing = false;
      if (this.inputType === 'select') {
        this.formControl.markAsPristine();
      }
    });
  }

  openedChange(open: boolean): void {
    if (!open && this.formControl.pristine) {
      this.escWithoutSaving();
    }
  }

  escWithoutSaving(): void {
    this.isEditing = false;
  }

  refreshInnerHtml(label: string): void {
    const customLabel = label ?? '-';
    this.innerHtml = `<${this.htmlTag}>${customLabel}</${this.htmlTag}>`;
  }

  selectDIsplayValue(value: string): string {
    const t = this.selectArray.find(
      c => c[this.selectValueField.valueOf()] === value,
    );
    return t[this.selectDisplayField.valueOf()];
  }

  moveMap(): void {
    const move = (): void => {
      if (this.showMap) {
        const mapNode = document.getElementById('map-detail');
        this.nodeToAttachMap.appendChild(mapNode);
      }
    };
    setTimeout(move);
  }

  private initAutocomplete(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const options = {
        fields: ['formatted_address', 'geometry', 'name'],
        strictBounds: false,
        types: ['address'],
      };

      const autocomplete = new google.maps.places.Autocomplete(
        this.mapSearch.nativeElement as HTMLInputElement,
        options,
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          const place = autocomplete.getPlace();

          //verify result
          if (!place.geometry) {
            return;
          }

          //set latitude, longitude and zoom
          const address = place.formatted_address;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          const location = new Location(
            address,
            this.latitude.toString(),
            this.longitude.toString(),
          );
          this.formControl.setValue(location);
          this.editingEnd(location.address);
        });
      });
    });
  }
}
