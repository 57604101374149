import { createAction, props } from '@ngrx/store';
import { Configuration } from '../models/configurations.model';

export const getConfig = createAction(
  '[CONFIGURATIONS] Get configuration',
  props<{ configId: string }>()
);
export const getConfigSuccess = createAction(
  '[CONFIGURATIONS] Get configuration success',
  props<{ data: Configuration }>()
);
export const getConfigFail = createAction(
  '[CONFIGURATIONS] Get configuration fail',
  props<{ error: any }>()
);

export const getAllConfigs = createAction(
  '[CONFIGURATIONS] Get all configurations'
);
export const getAllConfigsSuccess = createAction(
  '[CONFIGURATIONS] Get all configurations success',
  props<{ data: Configuration[] }>()
);
export const getAllConfigsFail = createAction(
  '[CONFIGURATIONS] Get all configurations fail',
  props<{ error: any }>()
);