import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { PermissionEnum, PermissionType } from '@model';

const appName = 'portfolioManagement';
const CACHE_SIZE = 1;
const API_ENDPOINT = 'user/permissions';
const REFRESH_INTERVAL = 60000;

@Injectable()
export class PermissionService {
  private permissions$: Observable<PermissionType>;
  private permissions: PermissionType;

  constructor(private resourceService: ResourceService<PermissionType>) {}

  getPermissions(): Observable<PermissionType> {
    if (!this.permissions$) {
      // Set up timer that ticks every X milliseconds
      const timer$ = timer(0, REFRESH_INTERVAL);

      // For each tick make a http request to fetch new data
      this.permissions$ = timer$.pipe(
        switchMap(() => this.requestPermissions()),
        tap(permissions => (this.permissions = permissions)),
        shareReplay({ bufferSize: CACHE_SIZE, refCount: false }),
      );
    }
    return this.permissions$;
  }

  hasPermission(requiredPermissions: PermissionEnum[]): boolean {
    const appPermissions = this.permissions[appName] ?? [];

    if (!appPermissions.length || !requiredPermissions.length) {
      return false;
    }

    return requiredPermissions.every(permission =>
      appPermissions.includes(permission),
    );
  }

  private requestPermissions(): Observable<PermissionType> {
    return this.resourceService.get(API_ENDPOINT);
  }
}
