import { createFeatureSelector, createSelector } from "@ngrx/store";
import { getCoreStore } from "../../reducers";
import { ConfigurationsState, CoreStore } from "../models/core.model";

export const selectConfig = createSelector(getCoreStore, (state: CoreStore) => state.config)

export const selectConfigList = createSelector(selectConfig,
  (state: ConfigurationsState) => state.list);

export const selectConfigLoader = createSelector(selectConfig,
  (state: ConfigurationsState) => state.loading);
