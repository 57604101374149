import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode } from 'ngx-tree-selector';
import { Subscription } from 'rxjs';
import {
  CookieService,
  LocalesService,
  RouterService,
  SidenavService,
  TreeService,
} from '@service';
import { Store } from '@ngrx/store';
import { getAllConfigs } from 'src/app/core/store/actions/configuration.actions';
import { selectConfigList, selectConfigLoader } from 'src/app/core/store/selectors/configurations.selector';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'rtu-dashboard-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class TreeComponent implements OnInit, OnDestroy {
  loading = true;
  selectedProjectId: string;
  selectedScenarioId: string;

  originalDataSource: TreeNode[];
  subscribers: Subscription[] = [];

  multiSelect = false;
  projectTree: TreeNode[] = [];
  selection: SelectionModel<TreeNode>;

  constructor(
    private router: Router,
    private sidenavService: SidenavService,
    private ngZone: NgZone,
    private cookieService: CookieService,
    private listService: TreeService,
    private localesService: LocalesService,
    private routerService: RouterService,
    private readonly store: Store,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.selection = new SelectionModel<TreeNode>();

    this.store.dispatch(getAllConfigs())
    this.matIconRegistry.addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg-icons/add-comments.svg'));
  }

  ngOnInit(): void {
    this.store.select(selectConfigList).subscribe(
      (listState) => {
        console.log(listState);
      }
    );
    this.store.select(selectConfigLoader).subscribe(
      (loader) => {
        this.loading = loader;
      }
    );
    // this.forceStopLoader(); // commented for the ngrx test

    const openProjectNodeSubscription =
      this.listService.openProjectNodeInList$.subscribe(projectId => {
        const projectIndex = this.projectTree.findIndex(
          c => c.id === projectId,
        );
        this.openProjectNode(projectIndex);
      });

    const deselectSubscription = this.listService.deselectAll$.subscribe(() =>
      this.deselectAll(),
    );

    this.subscribers.push(
      openProjectNodeSubscription,
      deselectSubscription,
    );
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(subscriber => subscriber.unsubscribe());
  }

  private forceStopLoader() {
    setTimeout(() => this.loading = false, 2000);
  }

  initSelection(selection: SelectionModel<TreeNode>): void {
    this.selection = selection;

    const selectionSub = this.selection.changed.subscribe(() => {
      if (this.selection?.selected?.length > 0) {
        if (this.selection.selected[0]?.space_type === 'PROJECT') {
          this.onProjectClicked(this.selection.selected[0]);
        }
        if (this.selection.selected[0]?.space_type === 'SCENARIO') {
          if (!this.listService.doNotChangeRoute) {
            this.onScenarioClicked(this.selection.selected[0]);
          } else {
            this.listService.doNotChangeRoute = false;
          }
        }
      }
    });

    this.subscribers.push(selectionSub);
  }

  onProjectClicked(project: TreeNode): void {
    this.sidenavService.closeIfMobile();
    this.selectedProjectId = project.id;
    if (!this.routerService.isNavigating) {
      this.ngZone.run(() => {
        this.router.navigateByUrl(`/details/${project.id}/view-project`);
      });
    }
  }

  onScenarioClicked(scenario: TreeNode): void {
    this.sidenavService.closeIfMobile();
    this.selectedScenarioId = scenario.id;
    this.ngZone.run(() => {
      this.router.navigate([`/details/${scenario.id}/view-scenario`], {
        queryParams: { projectId: scenario['parentId'] },
      });
    });
  }

  private openScenario(
    projectNodeIndexTree: number,
    scenarioIndex: number,
  ): void {
    this.openProjectNode(projectNodeIndexTree);
    setTimeout(
      () =>
        this.selection.select(
          this.projectTree[projectNodeIndexTree].children[scenarioIndex],
        ),
      200,
    );
  }

  private isProjectNodeOpen(projectNodeIndexTree: number): boolean {
    const liElement =
      this.getButton(projectNodeIndexTree)?.parentElement?.parentElement;
    if (!liElement) {
      return false;
    }
    return Array.from(liElement.childNodes).some(c => {
      return (<HTMLElement>c).tagName === 'UL';
    });
  }

  private openProjectNode(projectNodeIndexTree: number): void {
    if (!this.getButton(projectNodeIndexTree)) {
      setTimeout(() => this.openProjectNode(projectNodeIndexTree), 200);
      return;
    }
    const isOpen = this.isProjectNodeOpen(projectNodeIndexTree);
    if (!isOpen) {
      setTimeout(() => this.getButton(projectNodeIndexTree).click(), 50);
    }
  }

  private getButton(projectNodeIndexTree: number): HTMLButtonElement {
    return document.querySelector(
      `[aria-label="toggle ${this.projectTree[projectNodeIndexTree]['displayLabel']}"]`,
    );
  }

  private deselectAll(): void {
    this.selection.deselect(...this.selection.selected);
  }
}
