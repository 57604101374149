<div class='flex flex-col justify-start sidenav-body' *ngIf="!loading">
    <div class='table-container' *ngIf='originalDataSource?.length'>
        <tree-selector data-automation="tree-selector"
                [treeData]='projectTree'
                [multiSelect]='multiSelect'
                labelIdentifier='displayLabel'
                (selection)="initSelection($event)">
        </tree-selector>
    </div>
    <div class="list-empty" *ngIf="!loading && !originalDataSource?.length">
      {{ 'dashboard.list.none_configured' | translate }}
    </div>
    <div class='flex justify-end button-container'>
        <button
                id="create-button"
                (click)='createProject()'
                class='icon-btn primary-btn'
                data-automation="createProjectButton"
                matRipple
                disabled
        >
            <mat-icon>add</mat-icon>
            <span class="span">{{ 'dashboard.list.button.create' | translate }}</span>
        </button>
    </div>
</div>
<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
