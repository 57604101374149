import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guard';
import { PermissionEnum } from '@model';
import {
  DashboardResolver,
} from '@resolver';
import {
  DashboardComponent,
  DashboardModule,
} from '@view';

enum Routing {
  doc = 'doc',
  list = 'list',
  configured = 'configured',
  history = 'history',
  root = '',
}

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
  enableTracing: false,
};

const routes: Routes = [
  {
    path: Routing.root,
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.read] },
    resolve: { updates: DashboardResolver },
  },
  {
    path: Routing.doc,
    redirectTo: '/doc/index.html',
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    DashboardModule,
    RouterModule.forRoot(routes, routerOptions),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, DashboardResolver],
})
export class CoreRoutingModule { }
