import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TreeService {
  private _doNotChangeRoute = false;
  get doNotChangeRoute(): boolean {
    return this._doNotChangeRoute;
  }
  set doNotChangeRoute(value: boolean) {
    this._doNotChangeRoute = value;
  }

  private readonly deselectAllEmitter = new Subject<void>();
  deselectAll$ = this.deselectAllEmitter.asObservable();

  private readonly openProjectNodeInListEmitter = new Subject<string>();
  openProjectNodeInList$ = this.openProjectNodeInListEmitter.asObservable();

  private readonly enableFilterInputEmitter = new Subject<boolean>();
  enableFilterInput$ = this.enableFilterInputEmitter.asObservable();

  enableFilterInput(): void {
    this.enableFilterInputEmitter.next(true);
  }

  deselectAll(): void {
    this.deselectAllEmitter.next();
  }
}
