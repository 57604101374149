<div class="w-auto">
    <div class="flex justify-start items-center" *ngIf="!isEditing">
        <div data-automation="editableLabel" [innerHTML]="innerHtml"></div>
        <div *ngIf="inputType === 'map' && label" (click)="showMap = !showMap; moveMap()" class="flex items-center pointer mb2">
            <img *ngIf="!showMap" data-automation="map-icon" src="../../../../../assets/svg-icons/map2.svg" class="map-icon" id="map"  alt=""/>
            <img *ngIf="showMap" data-automation="map-icon" src="../../../../../assets/svg-icons/map3.svg" class="map-icon" id="map"  alt=""/>
        </div>
        <mat-icon svgIcon="edit" data-automation="pencil-icon" class="icon" (click)="editMode()"></mat-icon>
    </div>
</div>

<div id="map-detail" class="map-container" *ngIf="showMap">
    <map-view [zoom]="15" [usePanning]="true" [latitude]="latitude" [longitude]="longitude">
        <map-marker [latitude]="latitude" [longitude]="longitude"></map-marker>
    </map-view>
</div>

<div *ngIf='isEditing'>
    <mat-form-field [ngSwitch]="inputType" appearance='outline' [style.width.px]="inputType === 'map' ? 360 : 180">
        <input rtuAutofocus matInput
               data-automation="input-edit-mode"
               *ngSwitchCase="'input'"
               [formControl]="formControl"
               (focusout)='editingEnd(formControl.value)'
               (keyup.enter)='editingEnd(formControl.value)'
               (keyup.escape)='escWithoutSaving()'
               required>

        <input rtuAutofocus matInput #mapSearch
               data-automation="map-edit-mode"
               *ngSwitchCase="'map'"
               [formControl]="formControl"
               [ngModel]="label"
               (keyup.escape)='escWithoutSaving()'
               required>

        <mat-select #select
                data-automation="select-edit-mode"
                *ngSwitchCase="'select'"
                [formControl]="formControl"
                (selectionChange)="editingEnd(selectDIsplayValue($event.value))"
                (openedChange)="openedChange($event)"
                (keyup.escape)='escWithoutSaving()'
                required>
            <mat-option data-automation="mat-option-select" *ngFor="let value of selectArray" [value]="value[selectValueField]" id="{{'timezone-' + value[selectIdField]}}">
                {{ value[selectDisplayField] }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="formControl.hasError('required')">
            {{ 'dashboard.edit.name.error.required' | translate }}
        </mat-error>
    </mat-form-field>
</div>
