import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { TreeSelectorModule } from 'ngx-tree-selector';
import { TreeComponent } from './tree.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [TreeComponent],
  exports: [TreeComponent],
  imports: [
    CommonModule,
    TreeSelectorModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatRippleModule,
    MatIconModule
  ],
})
export class TreeModule {}
